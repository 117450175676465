import $ from 'jquery';
import { $$ } from '../../util';
import Store from '../../store';
import User from '../../user';
import Base from '../../base';
import Query from '../../query';
import App from '../../app';
import setImmediate from '../../set-immediate';
import namespace_tableTemplate from '../instruction/namespace_table.mold';
import queryoption_tableTemplate from '../instruction/query_option_table.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var editor = null,
    query = $arg.query || $arg.text || '',
    type = $arg.type,
    queryUuid = '',
    unlimitedResults = $arg.unlimitedResults || false,
    graphqlDefaultPrefix = $arg.graphqlDefaultPrefix || '',
    resultsNode = null;
    
    App.setQueryOptions($arg);

  function showNamespaces() {
    $('#toggleNamespace').text('Hide namespaces');
    $$('namespace-room').style.display = 'block';
    var ctrl = new Store.OptionSectionController(User.user.namespaces, {
      canSelect: false,
      canDelete: true,
      onDelete: function (ns) {
        User.deleteUserNamespace(ns.prefix, ns.type).then(showNamespaces);
      }
    });
    namespace_tableTemplate.cast('namespace-room-body', ctrl);
  }

  function hideNamespaces() {
    $('#toggleNamespace').text('Show namespaces');
    $$('namespace-room').style.display = 'none';
  }

  function areNamespacesShown() {
    var room = $$('namespace-room');
    return room && room.style.display === 'block';
  }

  function toggleNamespaces() {
    if (areNamespacesShown()) {
      hideNamespaces();
    } else {
      showNamespaces();
    }
  }
  
  function currentQuery(moreQueryOptions) {
    let globalOptions = App.getQueryOptions();
    let query = {
      query: editor.getValue(),
      type: type,
      uuid: queryUuid,
      unlimitedResults: unlimitedResults,
    };
    if (graphqlDefaultPrefix) {
      query.graphqlDefaultPrefix = graphqlDefaultPrefix;
    }
    return Object.assign(query, globalOptions, moreQueryOptions);
  }

  function showQueryOptions() {
    $('#toggleQueryOptions').text('Hide query options');
    $$('query-option-room').style.display = 'block';
    var ctrl = new Store.OptionSectionController(User.user.queryOptions, {
      canSelect: false,
      canDelete: true,
      onDelete: function (qo) {
        User.deleteUserQueryOption(qo.name, qo.type).then(showQueryOptions);
      }
    });
    queryoption_tableTemplate.cast('query-option-room-body', ctrl);
  }

  function hideQueryOptions() {
    $('#toggleQueryOptions').text('Show query options');
    $$('query-option-room').style.display = 'none';
  }

  function areQueryOptionsShown() {
    var room = $$('query-option-room');
    return room && room.style.display === 'block';
  }

  function toggleQueryOptions() {
    if (areQueryOptionsShown()) {
      hideQueryOptions();
    } else {
      showQueryOptions();
    }
  }

__out.push("\n\n<div id=\"query-page\">\n  <div class=\"query\">\n    <h2 id=\"query-title\">");
__out.push(Mold.escapeHTML($arg.title || "Edit query"));
__out.push("</h2>\n    <table class=\"fixed-width-table\" id=\"query-input-and-settings\">\n      <tr id=\"graphql-options\" style=\"display: ");
__out.push(Mold.escapeHTML((type === 'GraphQL') ? "block" : "none"));
__out.push("\">\n        <td>\n          Default prefix:\n          <input type=\"text\" id=\"graphql-default-prefix\" value=\"");
__out.push(Mold.escapeHTML(graphqlDefaultPrefix));
__out.push("\" />\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
graphqlDefaultPrefix = $node.value;
});}) + "\"></var>");
__out.push("\n        </td>\n        <td>\n          ");
Mold.dispatchCustom("help", " container=graphql-options-help\n           Default prefix can be either one of the predefined namespaces followed by a colon (e.g. <i>rdf:</i>)\n          or a full URI (e.g. <i>http://www.w3.org/1999/02/22-rdf-syntax-ns#</i>) that\n          will be prepended to all non-IRI fields in the GraphQL query.<br>\n          Default prefix can also be defined with a magic comment inside of the GraphQL query.<br>\n          Predefined namespace: <i>#@default ex:</i><br>\n          Full IRI: <i>#@default &lt;http://example.com/&gt;</i><br>\n          <br>\n          IRI Refs can be directly embedded inside of the query using the <i>&lt;prefix:name&gt;</i>\n          syntax: <i>&lt;ex:name&gt; </i> or<i> &lt;http://example.com/foo&gt;.</i><br>\n          Note that the second example works because the GraphQL parser maps <i>http:</i>, <i>https:</i> and <i>urn:</i>  to themselves instead of treating them as namespaces.\n\t  <br><br>\n          You can define a namespace either by using the AllegroGraph WebView interface, or with\n          a magic comment inside of the GraphQL query: <i>#@prefix ex: &lt;http://example.com&gt;</i>\n\t  <br><br>\n\t  You can define an alias (not the same as a GraphQL alias) that transforms a name\n\t  inside the GraphQL expression to another name.  This is useful to save repeatedly\n\t  typing a huge IRI.\n\t  <br>\n\t  Example: <i>#@alias jet &lt;http://faa.gov/part125/aircraft/powered/civilian/jet&gt;</i>", __out);
__out.push("\n        </td>\n      </tr>\n      <tr>\n        <td>\n          <span id='graphql-options-help'></span>\n        </td>\n      </tr>\n      <tr>\n        <td id=\"query-input\">\n          <textarea id=\"queryText\">");
__out.push(Mold.escapeHTML(query ));
__out.push("</textarea>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){editor = Query.initQueryEditor(type, $node);}) + "\"></var>");
__out.push("\n          <div class=\"editorResizeHandle\" id=\"queryTextResizeHandle\"></div>\n        </td>\n\n        <td id=\"query-options\">\n          <span class=\"query-option\">\n            <label for=\"querylanguage\" id=\"querylanguagelabel\">Language:</label>\n            <select class=\"querytype\" id=\"querylanguage\">\n              ");
Mold.forDepth++;
Mold.forEach(Query.LANGUAGES, function(lang, $i) {
__out.push("\n                ");
if (lang.requiredPerm === null || User.userPerm(lang.requiredPerm) ) {
__out.push("\n                  <option value=\"");
__out.push(Mold.escapeHTML(lang.name));
__out.push("\">");
__out.push(Mold.escapeHTML(lang.name));
__out.push("</option>\n                ");
}
__out.push("\n              ");
});
Mold.forDepth--;
__out.push("\n            </select>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
type = $node.value;
              Query.changeQueryLanguage(editor, type);
              Query.setQueryState('ready', type);
            
});}) + "\"></var>");
__out.push("\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$('#querylanguage').val(type);
            }) + "\"></var>");
__out.push("\n          </span>\n          <span class=\"query-option\">\n            <label>\n\t      <input class=\"centered-checkbox\" type=\"checkbox\" id=\"limitResults\"\n                     ");
if (type === 'GraphQL') {
__out.push("disabled");
}
__out.push("\n                     ");
if ((type !== 'GraphQL') && (! unlimitedResults)) {
__out.push("checked");
}
__out.push("/>\n\t      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
unlimitedResults = ! $node.checked;
});}) + "\"></var>");
__out.push("\n\t      <label for=\"limitResults\">Limit to ");
__out.push(Mold.escapeHTML($arg.rowsToFetch));
__out.push(" results</label>\n\t    </label>\n          </span>\n          ");
if (App.isInRepoOrSession()) {
__out.push("\n            <span class=\"query-option\">\n              <input class=\"centered-checkbox\" type=\"checkbox\" id=\"reasoning\" ");
if (App.useReasoning) {
__out.push("checked");
}
__out.push("/>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleReasoning($node.checked);
});}) + "\"></var>");
__out.push("\n              <label for=\"reasoning\" title=\"Turn reasoning on/off\">Reasoning</label>\n            </span>\n          ");
}
__out.push("\n          <span class=\"query-option\">\n            <input class=\"centered-checkbox\" type=\"checkbox\" id=\"showlongparts\" ");
if (App.showLongParts) {
__out.push("checked");
}
__out.push("/>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleLongParts($node.checked);
                    Query.redisplay(); 
});}) + "\"></var>");
__out.push("\n            <label for=\"showlongparts\" title=\"Display full URIs when showing parts\">Long parts</label>\n          </span>\n          <span class=\"query-option\">\n            <input class=\"centered-checkbox\" type=\"checkbox\" id=\"cancelonwarnings\" ");
if (App.cancelOnWarnings) {
__out.push("checked");
}
__out.push("/>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleCancelOnWarnings($node.checked); 
});}) + "\"></var>");
__out.push("\n            <label for=\"cancelonwarnings\" title=\"Cancel query if there are any warnings\">Cancel on warnings</label>\n          </span>\n          <span class=\"query-option\">\n            <input class=\"centered-checkbox\" type=\"checkbox\" id=\"mjqe\" ");
if (App.useMJQE) {
__out.push("checked");
}
__out.push("/>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleMJQE($node.checked);
});}) + "\"></var>");
__out.push("\n            <label for=\"mjqe\" title=\"Insert an AllegroGraph SPARQL query option that enables MJQE\">Use MJQE</label>\n            ");
Mold.dispatchCustom("help", " container=mjqe-help\n              MJQE is an alternate SPARQL query engine that is highly optimized for Path Expression\n\t      based queries. MJQE is still in development so it must be enabled for use.\n              <br><br>\n              Note that enabling MJQE here only affects queries run from AllegroGraph WebView\n              (query option is applied on per-query basis for every query opened in the query\n              editor). If you want to enable it globally (for current user and repository),\n              please use the \"Add a query option\" button to set the query option \"engine\" to\n              the \"mjqe\" value.\n\t      <br><br>\n\t      See <a target='_blank' class='linkish'\n              href='https://franz.com/agraph/support/documentation/current/query-engines.html'\n              >AllegroGraph's SPARQL Query Engines</a> for more details.", __out);
__out.push("\n          </span>\n          <span class=\"query-option\">\n            <button id=\"toggleNamespace\" title=\"Current user's persistent namespace declarations for this repository\">Show namespaces</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
toggleNamespaces();
});}) + "\"></var>");
__out.push("\n          </span>\n          <span class=\"query-option\">\n            <button title=\"Add a persistent namespace declaration for this repository for current user\">Add a namespace</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.addUserNamespace().then(function () {
                  if (areNamespacesShown()) {
                    // Reload namespaces:
                    showNamespaces();
                  }
                });
            
});}) + "\"></var>");
__out.push("\n          </span>\n          <span class=\"query-option\">\n            <button id=\"toggleQueryOptions\" title=\"Current user's persistent query options for this repository\">Show query options</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
toggleQueryOptions();
});}) + "\"></var>");
__out.push("\n          </span>\n          <span class=\"query-option\">\n            <button title=\"Set a persistent query option for this repository for current user\">Add a query option</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.addUserQueryOption().then(function () {
                  if (areQueryOptionsShown()) {
                    // Reload query options:
                    showQueryOptions();
                  }
                });
            
});}) + "\"></var>");
__out.push("\n          </span>\n          ");
if (App.currentSession && User.userPerm("eval")) {
__out.push("\n            <span class=\"query-option\">\n              <button>Add Prolog functors</button>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Query.addFunctors();
});}) + "\"></var>");
__out.push("\n            </span>\n          ");
}
__out.push("\n          ");
if (User.userPerm("super") && !App.currentSession) {
__out.push("\n            <span class=\"query-option\">\n              <button>Edit initfile</button>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
App.goTo('#initfile'); 
});}) + "\"></var>");
__out.push("\n            </span>\n          ");
}
__out.push("\n          <span class=\"query-option\">\n            ");
function updateLink(node) {
                  node.href = Query.makeQueryLink(type, editor.getValue());
                }
            
__out.push("\n            <a href=\"#\"><i class=\"permalink-icon fas fa-link\"></i> Permalink to query</a>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.href = Query.makeQueryLink(type, query);}) + "\"></var>");
__out.push("\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "focus", function($event, $node) {
updateLink($node);
});}) + "\"></var>");
__out.push("\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "mousedown", function($event, $node) {
updateLink($node);
});}) + "\"></var>");
__out.push("\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "mouseover", function($event, $node) {
updateLink($node);
});}) + "\"></var>");
__out.push("\n          </span>\n        </td>\n      </tr>\n    </table>\n\n\n    <div id=\"query-execution\">\n      <table width=\"100%\">\n        <tr><td><span id='mjqe-help'></span></td></tr>\n        <tr>\n          <td>\n            ");
function executeAndToggle() {
                if (Query.getQueryState() === 'ready') {
                  execute();
                } else {
                  Query.cancelQuery(type, queryUuid, resultsNode);
                }
              }

              function execute(moreQueryOptions) {
                var query = currentQuery(moreQueryOptions);
                queryUuid = Query.randomString();
                query.uuid = queryUuid;
                Query.executeQuery(query, resultsNode);
              }
            
__out.push("\n            <button id=\"executeButton\">Execute</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
executeAndToggle();
});}) + "\"></var>");
__out.push("\n            <button id=\"logButton\">Log Query</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
execute({logQuery: true});
});}) + "\"></var>");
__out.push("\n            <button id=\"planButton\">Show Plan</button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
execute({returnPlan: true});
});}) + "\"></var>");
__out.push("\n\n            ");
function submit() {
                  var query = currentQuery();
                  query.title = $$('query-name').value;
                  Query.saveQuery(query);
                }
            
__out.push("\n\n            ");
if (User.isLoggedIn() && App.isInRealRepo()) {
__out.push("\n              &nbsp; &nbsp; <button>Save</button>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n              as <input type=\"text\" id=\"query-name\" value=\"");
__out.push(Mold.escapeHTML($arg.title || ''));
__out.push("\"/>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n            ");
}
__out.push("\n            ");
if (User.userPerm("super") && App.isInRealRepo()) {
__out.push("\n              &nbsp; &nbsp; <button>Add to repository</button>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var query = currentQuery();
                  query.title = $$('query-name').value;
                  Store.addRepoQuery(query);
});}) + "\"></var>");
__out.push("\n            ");
}
__out.push("\n          </td>\n        </tr>\n      </table>\n    </div>\n    <span id=\"namespace-room\" style=\"display: none\">\n      <h3>Namespaces</h3>\n      <p id=\"namespace-room-body\"></p>\n    </span>\n    <span id=\"query-option-room\" style=\"display: none\">\n      <h3>Query options</h3>\n      <p id=\"query-option-room-body\"></p>\n    </span>\n  </div>\n\n  <div id=\"functor-space\"></div>\n\n  <div id=\"query-staleness-note\"></div>\n  <div id=\"query-results\">\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(Mold.setLabel("resultsNode")) + "\"></var>");
__out.push("\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){resultsNode = $node; }) + "\"></var>");
__out.push("\n    <div class=\"query-result\" id=\"query-result-space\"></div>\n  </div>\n\n</div>\n\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  Query.setQueryState("ready", type);
  Query.maybeReloadQueryData(editor.getValue(), resultsNode);
  hideNamespaces();
  hideQueryOptions();
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;