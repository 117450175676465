import Util from './util';
import _ from 'underscore';
import Base from './base';
import App from './app';
import $ from 'jquery';
import Catalog from './catalog';
import Server from './server';
import Dispatch from './dispatch';
import User from './user';
import Layout from './layout';
import { $$ } from './util';
import inputTemplate from './templates/query/input.mold';
import loadingTemplate from './templates/instruction/loading.mold';
import noresultTemplate from './templates/query/noresult.mold';
import logTemplate from './templates/query/log.mold';
import planTemplate from './templates/query/plan.mold';
import informationOnlyTemplate from './templates/query/information-only.mold';
import tabbedResultTemplate from './templates/query/tabbed-result.mold';
import resultsTemplate from './templates/query/results.mold';
import boolTemplate from './templates/query/bool.mold';
import cancelingTemplate from './templates/instruction/canceling.mold';
import savedTemplate from './templates/query/saved.mold';
import recentTemplate from './templates/query/recent.mold';
import functorTemplate from './templates/query/functor.mold';
import graphqlTemplate from './templates/query/graphql.mold';

import tableTemplate from './templates/query/table.mold';
import triplesTemplate from './templates/query/triples.mold';

/**
 * Functionality related to the query interface.
 * @namespace Query
 */
var Query = {};

Query.DEFAULT_QUERY_CHUNK_SIZE = 1000;
Query.MAX_QUERY_RESULTS_SIZE = 10000;


// [Title, MIME, abbrev, canSerializeAs]
Query.TRIPLE_CONTENT_TYPES = [
  ['N-Triples', 'text/plain', 'nt', true],
  ['N-Quads', 'text/x-nquads', 'nq', true],
  ['Extended N-Quads (NQX)', 'application/x-extended-nquads', 'nqx', true],
  ['RDF/XML', 'application/rdf+xml', 'xml', true],
  ['TriG', 'application/trig', 'trig', true],
  ['TriG*', 'application/trig+star', 'trig', true],
  ['Turtle', 'text/turtle', 'ttl', true],
  ['Turtle*', 'text/turtle+star', 'ttl', true],
  ['JSON-LD', 'application/ld+json', 'jsonld', false],
];

Query.TABLE_CONTENT_TYPES = [
  ['SPARQL JSON ', 'application/sparql-results+json', 'json', true],
  ['SPARQL XML', 'application/sparql-results+xml', 'xml', true],
  ['SPARQL TTL ', 'application/sparql-results+ttl', 'ttl', true],
  // ["SPARQL Tab-Separated Values ", "text/tab-separated-values", "tsv", true],
  ['SPARQL Comma-Separated Values', 'text/csv', 'csv', true],
  ['Comma-Separated Values', 'application/processed-csv', 'csv', true],
  [
    'Comma-Separated Values (with fields in N-Triples syntax)',
    'text/simple-csv', 'csv', true,
  ],
];

Query.LANG_SPARQL = {
  name: 'SPARQL',
  editorMode: 'application/sparql-query',
  commentMarker: '#',
  defaultQuery: ['', '# View triples', 'SELECT ?s ?p ?o { ?s ?p ?o }'],
  requiredPerm: null, // means no required permission
};
Query.LANG_PROLOG = {
  name: 'Prolog',
  editorMode: 'text/x-common-lisp',
  commentMarker: ';;',
  defaultQuery: ['', ';; View triples', '(select (?s ?p ?o) (q- ?s ?p ?o))'],
  requiredPerm: 'eval',
};
Query.LANG_GRAPHQL = {
  name: 'GraphQL',
  editorMode: 'application/json',
  commentMarker: '#',
  defaultQuery: ['', '# GraphQL'],
  requiredPerm: null, // means no required permission
};
Query.LANGUAGES = [Query.LANG_SPARQL, Query.LANG_PROLOG, Query.LANG_GRAPHQL];
Query.LANGUAGES.forEach(function (lang) {
  Query.LANGUAGES[lang.name] = lang;
});

// The query store (queries and queryMap variables) keeps track of
// recent queries in order to make back/forward work.
Query.queryHistory = (function () {
  var STORAGE_KEY = 'queries';
  var MAX_SIZE = 40;
  var RECENT_SIZE = 20;
  var queryList = [];

  function saveToStorage() {
    localStorage.setItem(STORAGE_KEY, Util.writeJSON(queryList));
  }

  function getFreeId() {
    if (queryList.length === 0) {
      return 1;
    }
    return _.max(queryList, 'id').id + 1;
  }

  return {

    init: function () {
      try {
        queryList = Util.readJSON(localStorage.getItem(STORAGE_KEY)) || [];
      } catch (e) {
        queryList = [];
      }
    },

    clear: function () {
      localStorage.removeItem(STORAGE_KEY);
      queryList = [];
    },

    moveToLast: function (query) {
      if (queryList[queryList.length - 1] !== query) {
        queryList = _.without(queryList, query);
        this.add(query);
      }
    },

    add: function (query) {
      if (query.id === undefined) {
        query.id = getFreeId();
      }
      queryList.push(query);
      if (queryList.length > MAX_SIZE) {
        queryList.shift();
      }
      saveToStorage();
    },

    findById: function (id) {
      return this.findSimilar({ id: id });
    },

    findSimilar: function (query) {
      return _.findWhere(queryList, query);
    },

    getRecent: function () {
      var lowest = Math.max(0, queryList.length - RECENT_SIZE);
      var recent = queryList.slice(lowest);
      recent.reverse();
      return recent;
    },

  };
})();

/**
 * Possible configuration objects for Query.QueryData instances.
 *
 * These objects modify Query.QueryData's behavior depending on the value
 * of resultType. The mapping is defined in Query.QUERY_DATA_CONFIGS.
 */
Query._DataConfigs = {
  Simple: {
    canDownload: function () {
      return false;
    },
  },
  Table: {
    canDownload: function (state) {
      return state.type !== 'AUDIT';
    },
    getColumnNames: function (state) {
      return state.firstResult.names;
    },
    downloadContentTypes: Query.TABLE_CONTENT_TYPES,
    resultsTemplate: tableTemplate,
  },
  Triples: {
    canDownload: function () {
      return true;
    },
    downloadContentTypes: Query.TRIPLE_CONTENT_TYPES,
    resultsTemplate: triplesTemplate,
  },
};

/**
 * Mapping between Query.QueryData's resultType value and Query._DataConfigs
 * configuration objects.
 */
Query.QUERY_DATA_CONFIGS = {
  boolean: Query._DataConfigs.Simple,
  log: Query._DataConfigs.Simple,
  plan: Query._DataConfigs.Simple,
  table: Query._DataConfigs.Table,
  triples: Query._DataConfigs.Triples,
};

/**
 * A complex object keeping track of the state of query results, and allowing
 * for consistent, clear handling of paging in multiple scenarios.
 *
 * The state of the object (contained in this._state) is JSON-compatible, i.e.
 * it can be safely serialized to JSON (see Query._currentQueryStorage).
 * Symmetrically, the constructor takes the state object, which allows for
 * deserialization. All behavior dependent on resultType is kept
 * in this._config, which is not serializable (so it can contain, e.g., function
 * definitions).
 *
 * Results of the query are added to this object in the
 * fromFirstResult() function and getVisibleValues() returns the
 * values that should be shown to the user.
 */
Query.QueryData = (function () {
  function QueryData(state) {
    this._state = state;
    this._config = Query.QUERY_DATA_CONFIGS[state.resultType];
    if (!this._config) {
      throw new Error('Unrecognized query result type: ' + state.resultType);
    }
  }

  QueryData.fromFirstResult = function (firstResult, query) {
    var type = query.type;
    var options = Object.assign({}, query);
    delete options.query;
    delete options.type;
    var state = {
      type: type,
      repo: App.getCurrentRepoOrSessionLabel(type === 'AUDIT'),
      query: query.query,
      options: options,
      resultType: firstResult.resultType,
      firstResult: firstResult,
      visibleValues: firstResult.values.slice(0, Query.MAX_QUERY_RESULTS_SIZE),
    };
    var resultLength = firstResult.values.length;
    if (resultLength > Query.MAX_QUERY_RESULTS_SIZE) {
      Layout.showMessage(
        'Showing ' + Query.MAX_QUERY_RESULTS_SIZE.toLocaleString() + ' results. ' +
          'The total number of results is ' + resultLength.toLocaleString() +
          '. Please use "Download" button below to get the complete result set.'
      );
      // Only set this if we slice the result array.
      state.totalLength = resultLength;
    }
    var queryData = new QueryData(state);
    queryData.setQueryTime(firstResult);
    return queryData;
  };

  $.extend(QueryData.prototype, {
    /**
     * @return {Object} - The serializable internal state of the object.
     */
    getState: function () {
      return this._state;
    },

    /**
     * @return {boolean} - Whether the user can download this query result.
     */
    canDownload: function () {
      return this._config.canDownload(this._state);
    },

    /**
     * @return {Array[]} - The list of formats in which the user can download
     *   this query result.
     */
    getDownloadContentTypes: function () {
      return this._config.downloadContentTypes;
    },

    /**
     * Fetches a file with representation of results of this query
     * in the given format.
     *
     * @param {string} format - Desired MIME-type of the file.
     */
    download: function (format) {
      var self = this;
      (Catalog.makeMaybeVerifyLargeOperationWarning(function () {
        var contentType = Util.findIf(function (contentType) {
          return contentType[1] === format;
        }, self.getDownloadContentTypes());
        if (contentType) {
          var target = Base.url(Base.serverUrl(''), {
            query: self._state.query,
            infer: App.useReasoning,
          });
          Server.download({
            file: 'query.' + contentType[2],
            accept: format, path: target,
          });
        }
      }))();
    },

    /**
     * @return {Array} - All query results currently visible to the user.
     */
    getVisibleValues: function () {
      return this._state.visibleValues;
    },

    /**
     * @return {number} - Number of results currently visible to the user.
     */
    getVisibleLength: function () {
      return this.getVisibleValues().length;
    },

    /**
     * @return {number} - Total number of results returned by the query.
     */
    getTotalLength: function () {
      return this._state.totalLength;
    },

    /**
     * @return {Object} - Mold template module to use when rendering the results.
     */
    getResultsTemplate: function () {
      return this._config.resultsTemplate;
    },

    /**
     * Executes the same query again and refreshes the results.
     *
     * @param {*} resultsNode - DOM node to render the results in.
     */
    reloadFromServer: function (resultsNode) {
      let query = Object.assign({
        type: this.getQueryType(),
        query: this.getQuery(),
      }, this._state.options);
      Query.loadQueryResults(query, resultsNode);
    },

    /**
     * @return {string[]} - Names of columns (if applicable for a particular
     *   resultType).
     */
    getColumnNames: function () {
      return this._config.getColumnNames(this._state);
    },

    /**
     * @return {Object|null} - Extra query execution information returned
     *   by the server during fetching the first batch of values.
     */
    getInformation: function () {
      return this._state.firstResult.information;
    },

    /**
     * @return {string|number} - The number of time units that the execution
     *   of the first batch of the query took, in units defined by
     *   getQueryTimeUnits, or '' if this information is not available.
     */
    getQueryTime: function () {
      return this._state.queryTime;
    },

    /**
     * @return {string} - Units in which getQueryTime returns its value,
     *   or '' if this information is not available.
     */
    getQueryTimeUnits: function () {
      return this._state.queryTimeUnits;
    },

    /**
     * @return {string} - Type of the query ("AUDIT", "SPARQL" or "Prolog").
     */
    getQueryType: function () {
      return this._state.type;
    },

    /**
     * @return {string} - Type of the result returned by the server in the
     *   first batch ("boolean", "log", "plan", "table" or "triples").
     */
    getResultType: function () {
      return this._state.resultType;
    },

    /**
     * @return {string|Object} query - Either the query string
     *   (if getQueryType() is SPARQL or Prolog), or a configuration object
     *   for AUDIT.
     */
    getQuery: function () {
      return this._state.query;
    },

    /**
     * @return {string} - Label of the repository or session this
     *   query was executed in.
     */
    getRepo: function () {
      return this._state.repo;
    },

    /**
     * @return {boolean} - Whether or not to request an unlimited number
     *   of rows or to use Query.DEFAULT_QUERY_CHUNK_SIZE.
     */
    getUnlimitedResults: function () {
      return this._state.options.unlimitedResults;
    },

    /**
     * Sets the values for getQueryTime() and getQueryTimeUnits() based on
     * information in the given query result.
     *
     * @param {Object} result - Result of the execution of the query
     *   on the server.
     */
    setQueryTime: function (result) {
      var time = result.information && result.information.time;
      if (time) {
        var queryTime = (time.plan || 0) + (time.query || 0);
        if (queryTime < 1) {
          this._state.queryTime = queryTime * 1000;
          this._state.queryTimeUnits = 'ms';
        } else {
          this._state.queryTime = queryTime;
          this._state.queryTimeUnits = 's';
        }
      } else {
        this._state.queryTime = '';
        this._state.queryTimeUnits = '';
      }
    },

    checkForStaleResults: function () {
      let self = this;
      Base.req('GET', 'generation').wait(function (gen) {
        let info = self.getInformation();
        let queryGen = info.other.generation;
        let stalenessDiv = $$('query-staleness-note');
        if (queryGen !== null && queryGen !== parseInt(gen)) {
          Util.setColoredText(stalenessDiv,
                           '[ Query results may be out of date. Click "Execute" to refresh. ]',
                           'red');
        }
      });
    },
  });

  return QueryData;
})();

/**
 * Convenience object for loading, saving and clearing localStorage data
 * that remembers the latest executed query.
 *
 * This is used e.g. if the user navigates away from the query results page
 * and wants to go back (using the browser's "back" button) and still
 * see the results of the query, without having to re-execute it.
 *
 * NOTE: to avoid hard-to-debug type errors, bump the version in keyName
 * every time you modify Query.QueryData structure.
 */
Query._currentQueryStorage = (function (keyName) {
  return {
    clear: function () {
      Util.removeFromStorage(keyName);
    },
    save: function (queryData) {
      Util.setInStorage(keyName, queryData.getState());
    },
    load: function () {
      var state = Util.getFromStorage(keyName);
      if (state) {
        return new Query.QueryData(state);
      } else {
        return null;
      }
    },
  };
})('wvCurrentQuery-v4');

// Add or lookup a query in the query store. Returns a numeric
// identifier for this query.
function storeQuery(query) {
  // uuid is generated each time query is executed
  let cleanQuery = Object.assign({}, query);
  delete cleanQuery.uuid;
  var found = Query.queryHistory.findSimilar(cleanQuery);
  if (found) {
    Query.queryHistory.moveToLast(found);
    return found.id;
  } else {
    Query.queryHistory.add(cleanQuery);
    return cleanQuery.id;
  }
}

/**
 * Display function for #.../query/NUM
 *
 * @param {Number|null} num - query number to load
 *   (if null, display empty query)
 */
Query.showQuery = function (num) {
  if (num === null) {
    Query.doShowQuery({ type: Query.LANG_SPARQL.name });
    return;
  }
  var found = Query.queryHistory.findById(num);
  if (!found) {
    App.goTo(Dispatch.relativeUrl('query'));
  } else {
    Query.doShowQuery(found);
  }
};

// Display function for #.../query/d/[query]
Query.showDirectQuery = function (type, query) {
  // Query type names use mixed case in code, but lower case in the direct url..
  if (type === 'prolog') {
    type = 'Prolog';
  } else if (type === 'graphql') {
    type = 'GraphQL';
  }
  Query.doShowQuery({ type: type, query: query }, true);
};

Query.makeQueryLink = function (type, query) {
  if (/^\s*$/.test(query)) {
    return Dispatch.relativeUrl('query');
  } else {
    return Dispatch.relativeUrl(
      'query/d/' + (type === 'SPARQL' ? '' : type.toLowerCase() + '/') +
        Util.encodeFragment(query));
  }
};

Query._refreshQueryPage = function (resultsNode) {
  if (Util.isInDocument(resultsNode)) {
    if (resultsNode.reloadFromServer) {
      resultsNode.reloadFromServer(resultsNode);
    } else {
      $(resultsNode).html('');
    }
  }
};

// Helper for the various things that need to display a query.
Query.doShowQuery = function (query, executeNow) {
  if (!User.userAccess('r')) {
    return User.denied();
  }

  query.rowsToFetch = Query.DEFAULT_QUERY_CHUNK_SIZE;

  var inputLabels = inputTemplate.cast(Layout.getPage(), query);
  var resultsNode = inputLabels.resultsNode;
  App.customRefresh = Query._refreshQueryPage.bind(Query, resultsNode);

  if (query.query) {
    storeQuery(query);
    if (executeNow) {
      Query.loadQueryResults(query, resultsNode);
    }
  }
  return null;
};

/**
 * Loads and shows the results of a query in the given DIV.
 *
 * @param {Object} query - an object representing the query and its options
 *  Entries:
 *   - {string} type - one of: "AUDIT", "SPARQL", "Prolog", "GraphQL"
 *   - {string} [title] - query's title if provided by the user
 *   - {boolean} unlimitedResults - whether or not to limit results
 *   - {string} [uuid] - unique identifier of the query (random)
 *   - {boolean} [logQuery] - whether to return the query log
 *                             instead of results
 *   - {boolean} [returnPlan] - whether to return the query plan
 *                               instead of results
 *   - {string}  [graphqlDefaultPrefix] - GraphQL-specific parameter -
 *                                         default prefix
 *   Query options that shadow their app-level counterparts (see app.js):
 *   - {boolean} [useMJQE] - App.useMJQE
 *   - {boolean} [showLongParts] - App.showLongParts
 *   - {boolean} [cancelOnWarnings] - App.cancelOnWarnings
 *   - {boolean} [useReasoning] - App.useReasoning
 *   Query itself:
 *   - {string|Object} query - either the query string
 *       (if type is SPARQL, Prolog or GraphQL), or a configuration object for AUDIT.
 *     Entries in the audit configuration object:
 *       - {string} users - comma-separated usernames
 *       - {string} events - comma-separated event URIs
 *       - {string} [startDate] - start date in ISO 8601 format
 *       - {string} [endDate] - end date in ISO 8601 format
 * @param {HTMLDivElement} resultsNode - where query results should be rendered
 */
Query.loadQueryResults = function (query, resultsNode) {
  let type = query.type;
  if (type === 'Prolog' && !User.userPerm('eval')) {
    Layout.showMessage(
      'You do not have sufficient permissions to evaluate Prolog queries.',
      null, true);
    return;
  }

  loadingTemplate.cast(resultsNode, { 'message': 'Executing...' });
  Query.setQueryState('running', type);
  query.limit =
    query.unlimitedResults ? null : Query.DEFAULT_QUERY_CHUNK_SIZE;
  Server.sendQueryRequest(query).protect(function () {
    $(resultsNode).html('');
  }).wait(Query.onQueryRequestSuccess.bind(
               Query, query, resultsNode),
           Query.onQueryRequestFailure.bind(Query, type, resultsNode));
};

/**
 * Called if the server call initiated from Query.loadQueryResults
 * returns successfully.
 *
 * @param {Object} query - query and its options (see Query.loadQueryResults).
 * @param {HTMLDivElement} resultsNode - where query results should be rendered
 * @param {Object|null} result - what the server returned (processed by
 *   Server._convertQueryResult)
 */
Query.onQueryRequestSuccess = function (query, resultsNode, result) {
  let type = query.type;
  Query._currentQueryStorage.clear();
  Query.setQueryState('ready', type);

  if (!result) {
    noresultTemplate.cast(resultsNode);
    return;
  }

  if (result.resultType === 'log') {
    logTemplate.cast(resultsNode, result);
    return;
  }

  if (result.resultType === 'plan') {
    planTemplate.cast(resultsNode, result);
    return;
  }

  let resultLength = result.values.length;

  if (resultLength === 0) {
    informationOnlyTemplate.cast(resultsNode, result);
    return;
  }

  if (type === 'GraphQL') {
    graphqlTemplate.cast(resultsNode, result);
    return;
  }

  var queryData = Query.QueryData.fromFirstResult(result, query);

  Query._renderQueryResultsData(resultsNode, queryData);
  // Only save results if there aren't too many (otherwise, local storage will complain)
  if (queryData.getVisibleLength() <= Query.DEFAULT_QUERY_CHUNK_SIZE) {
    Query._currentQueryStorage.save(queryData);
  }
  User.maybeShowLimitedResultsWarning('only a limited number results are shown');
};

/**
 * Called if the server call initiated from Query.loadQueryResults failed.
 *
 * @param {string} type - one of: "AUDIT", "SPARQL", "Prolog", "GraphQL"
 * @param {HTMLDivElement} resultsNode - where query results should be rendered
 * @param {string} message - failure reason
 */
Query.onQueryRequestFailure = function (
    type, resultsNode, message/* , extra */) {
  // Truncate the error message to this length (the message can be very long).
  var LENGTH_LIMIT = 512;
  noresultTemplate.cast(resultsNode);
  Query.setQueryState('ready', type);
  if (message !== 'Query canceled by user') {
    Base.failMessage('Executing query', Base.truncate(message, LENGTH_LIMIT));
  }
};

/**
 * Casts results of the query into a table inside a given outputDiv.
 *
 * @param {HTMLElement} outputDiv - target location of the table
 * @param {QueryData} queryData
 */
Query.castTable = function (outputDiv, queryData) {
  var resultsTemplate = queryData.getResultsTemplate();
  tabbedResultTemplate.cast(outputDiv, {
    resultsTemplate: resultsTemplate,
    queryData: queryData,
  });
};

Query._renderQueryResultsData = function (resultsNode, queryData, checkStaleness = false) {
  var labels = resultsTemplate.cast(resultsNode, queryData);
  var resultSpace = labels.queryResultSpace;
  if (queryData.getResultType() === 'boolean') {
    boolTemplate.cast(resultSpace, queryData);
  } else {
    Query.castTable(resultSpace, queryData);
    resultsNode.reloadFromServer = queryData.reloadFromServer.bind(queryData);
    Query.redisplay = function () {
      Query._renderQueryResultsData(resultsNode, queryData);
    };
    if (checkStaleness) {
      queryData.checkForStaleResults();
    }
  }
};

/*
 * Hack used to redisplay last query data without reloading it.
 * This will be set to a closure that contains the last request
 * data and results node.
 */
Query.redisplay = function () {};

/**
 * If the given query is the query for which we have results saved,
 * fills the results table.
 *
 * @param {string} query - query string
 * @param {HTMLDivElement} resultsNode - where query results should be rendered
 */
Query.maybeReloadQueryData = function (query, resultsNode) {
  var oldQueryData = Query._currentQueryStorage.load();
  if (oldQueryData &&
      oldQueryData.getRepo() === App.getCurrentRepoOrSessionLabel() &&
      oldQueryData.getQuery() === query) {
    Query._renderQueryResultsData(resultsNode, oldQueryData, true);
  }
};

var _state = 'ready';

Query.setQueryState = function (state, type) {
  _state = state;
  $('#executeButton').attr('data-state', state);
  updateQueryControls(type);
};

Query.getQueryState = function () {
  return _state;
};

function updateQueryControls(type) {
  var state = Query.getQueryState();
  var disabled = (state !== 'ready');
  var canLog = (type === 'SPARQL');
  var canPlan = (type === 'SPARQL');
  var canCancel = (type === 'SPARQL');
  var executeButton = $$('executeButton');
  var logButton = $$('logButton');
  var planButton = $$('planButton');
  var reasoningCheckbox = $$('reasoning');
  var showLongPartsCheckbox = $$('showlongparts');
  var cancelOnWarningsCheckbox = $$('cancelonwarnings');
  var mjqeCheckbox = $$('mjqe');
  var graphqlOptions = $$('graphql-options');

  var graphql = (type === 'GraphQL');

  var executeText;
  if (type === 'AUDIT') {
    executeText = 'View Audit Log';
  } else {
    executeText = 'Execute';
  }

  if (state === 'running') {
    if (canCancel) {
      executeButton.innerText = 'Cancel';
      executeButton.disabled = false;
    } else {
      executeButton.innerText = executeText;
      executeButton.disabled = true;
    }
  } else if (state === 'canceling') {
    executeButton.innerText = 'Canceling';
    executeButton.disabled = true;
  } else if (state === 'ready') {
    executeButton.innerText = executeText;
    executeButton.disabled = false;
  } else {
    throw new Error('Unknown query state: ' + state);
  }
  if (logButton) {
    logButton.disabled = disabled || !canLog;
  }
  if (planButton) {
    planButton.disabled = disabled || !canPlan;
  }

  function updateCheckbox(checkbox, checked, disabled) {
    if (checkbox) {
      checkbox.checked = checked;
      checkbox.disabled = disabled;
    }
  }
  // Handle 'Reasoning' checkbox.
  if (App.currentSession && App.currentSession.description.match(/with reasoner/)) {
    updateCheckbox(reasoningCheckbox, true, true);
  } else {
    updateCheckbox(reasoningCheckbox, App.useReasoning, false);
  }
  // Handle 'Use MJQE' checkbox.
  if (mjqeCheckbox) {
    if (type !== 'SPARQL') {
      if (mjqeCheckbox.checked) {
        mjqeCheckbox.checked = false;
        if (Query.queryOptionSet('engine', 'mjqe')) {
          Query.removeQueryOption('engine', 'mjqe');
        }
      }
      mjqeCheckbox.disabled = true;
    } else {
      if (App.useMJQE) {
        mjqeCheckbox.checked = true;
        if (!Query.queryOptionSet('engine', 'mjqe')) {
          Query.injectQueryOption('engine', 'mjqe');
        }
      }
      mjqeCheckbox.disabled = false;
    }
  }
  // Handle 'Long parts' checkbox.
  updateCheckbox(showLongPartsCheckbox, !graphql && App.showLongParts, graphql);
  // Handle 'Cancel on warnings' checkbox.
  updateCheckbox(cancelOnWarningsCheckbox, !graphql && App.cancelOnWarnings, graphql);
  // Handle 'GraphQL options' area.
  if (graphqlOptions) {
    graphqlOptions.style.display = (graphql) ? 'block' : 'none';
  }
}

Query.makeQueryOptionRE = function (name, value) {
  return '^\\s*PREFIX\\s+franzOption_' + name + ':\\s+<franz:' +
    (value ? value : '.*') + '>';
};

Query.injectQueryOption = function (name, value) {
  if (Query.currentEditor.get()) {
    var queryText = Query.currentEditor.get().getDoc().getValue();
    var newQueryText = 'PREFIX franzOption_' + name + ': <franz:' + value + '>\n\n' + queryText;
    Query.currentEditor.get().getDoc().setValue(newQueryText);
  }
};

Query.queryOptionSet = function (name, value) {
  if (Query.currentEditor.get()) {
    var queryText = Query.currentEditor.get().getDoc().getValue();
    return queryText.match(new RegExp(Query.makeQueryOptionRE(name, value), 'mi'));
  }
  return null;
};

Query.removeQueryOption = function (name, value) {
  if (Query.currentEditor.get()) {
    var queryText = Query.currentEditor.get().getDoc().getValue();
    var newQueryText = queryText.replace(
      new RegExp(Query.makeQueryOptionRE(name, value) + '\\s*\\n(\\s*\\n)?', 'gmi'), '');
    Query.currentEditor.get().getDoc().setValue(newQueryText);
  }
};

// cf. http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
Query.randomString = function () {
  var radix = 36;
  // Skip this many leading digits (not random enough?)
  var skipDigits = 2;
  // Take this many digits from each random number
  var takeDigits = 15;
  return Math.random().toString(radix).substring(skipDigits, takeDigits) +
    Math.random().toString(radix).substring(skipDigits, takeDigits);
};

/**
 * Cancels a running query.
 *
 * Fires when the user pressed the "Cancel" button
 * ("Execute" button turns into it during query execution).
 *
 * @param {string} type - ??
 * @param {string} uuid - unique identifier of the query (random)
 * @param {*} resultsNode - DOM node to render the results in.
 */
Query.cancelQuery = function (type, uuid, resultsNode) {
  cancelingTemplate.cast(resultsNode);
  Query.setQueryState('canceling', type);
  Base.jsonReq('DELETE', '/jobs?jobId=' + uuid)
      .wait(function (result) {
        if (!result) {
          $(resultsNode).html('');
          Query.setQueryState('ready', type);
        }
      });
};

// Fires when the user presses the execute button. Refreshes the query results.
Query.executeQuery = function (query, resultsNode) {
  Layout.removeMessages();
  $$('query-staleness-note').innerHTML = '';

  if (!/^\s*$/.test(query)) {
    var hash = Dispatch.relativeUrl(
      'query/' + storeQuery(query));
    Query.loadQueryResults(query, resultsNode);
    App.updateHash(hash);
  } else {
    Layout.showMessage('You did not enter a query.', Layout.SHORT_DELAY, false);
  }
};

function queryPrefix() {
  if (App.currentSession) {
    return 'query:' + App.currentSession.wrapping.catalog + ':' +
      App.currentSession.wrapping.name + ':';
  } else if (App.currentRepository) {
    return 'query:' + App.currentCatalog + ':' + App.currentRepository + ':';
  } else {
    throw new Error('Not in a repository or a session!');
  }
}

function queryTag(title) {
  return queryPrefix() + title;
}

// Saved queries at #query/s/NAME
Query.showSavedQuery = function (queryName) {
  var prefix = queryPrefix();
  if (!queryName) {
    // All saved queries requested.
    Base.load(
      User.getCurrentUserDataList(prefix), 'Fetching queries',
      function (list) {
        var mangledList = (list || []).map(function (q) {
          return q.id.slice(prefix.length + 'vw.'.length);
        });
        savedTemplate.cast(Layout.getPage(), mangledList);
      });
  } else {
    // One particular query was requested.
    Base.load(
      User.getCurrentUserData(queryTag(queryName), true), 'Fetching query',
      function (data) {
        if (!data) {
          Layout.showMessage('No query \'' + queryName + '\' found.');
        } else {
          Query.doShowQuery(data);
        }
      });
  }
};

Query.showRecent = function () {
  var recent = Query.queryHistory.getRecent();
  recentTemplate.cast(Layout.getPage(), recent);
};

/**
 * @param {string} type - user chooses a type of query he wants to execute:
 *        'Prolog' or 'SPARQL'
 * @param {string} textarea - element for creating codemirror object
 * @return {Object} - codemirror object
 */
Query.initQueryEditor = function (type, textarea) {
  var mode = Query.LANGUAGES[type] ? Query.LANGUAGES[type].editorMode : 'text/plain';
  var editor = App.codeMirrorFromTextArea(textarea, {
    mode: mode,
    resizeHandleId: 'queryTextResizeHandle',
  });
  // Checking if we have any saved state of query we are working on and no text in the textarea,
  // when we init a query editor. If it's true - load saved state of query.
  if ((Query.currentEditor.get() !== null) && !textarea.value.length) {
    var doc = Query.currentEditor.get().getDoc().copy(true);
    editor.swapDoc(doc);
  }

  Query.currentEditor.set(editor);
  editor.focus();
  return editor;
};

Query.currentEditor = (function () {
  var currentEditor = null;

  return {
    set: function (value) {
      currentEditor = value || null;
    },
    get: function () {
      return currentEditor;
    },
    clear: function () {
      currentEditor = null;
    },
  };
})();

// When user changes the query language from SPARQL to Prolog and vice
// versa, the previous query text is commented out, and the new
// default query is inserted.
Query.changeQueryLanguage = function (editor, type) {
  editor.setOption('mode', Query.LANGUAGES[type].editorMode);
};

// Saving a query means storing it in the server, updating the local
// query list, and going to that query's URL.
Query.saveQuery = function (query) {
  let title = query.title;
  if (!title || /^\s*$/.test(query.query)) {
    return;
  }
  let titleNode = $$('query-title');
  let json = Util.writeJSON(query);
  Base.load(
    User.setCurrentUserData(queryTag(title), json), 'Saving',
    function onSaved() {
      if (titleNode.parentNode) {
        $(titleNode).html(_.escape(title));
        var hash = Dispatch.relativeUrl('query/s/' + title);
        App.goTo(hash);
      }
    });
};

Query.deleteSavedQuery = function (name) {
  Base.load(
    User.delCurrentUserData(queryTag(name)),
    'Deleting', App.refresh.bind(App));
};

// Functor input

Query.addFunctors = function () {
  var node = $$('functor-space');
  if (node.firstChild) {
    return;
  }

  function close() {
    $(node).html('');
  }
  function make(node) {
    var cm = App.codeMirrorFromTextArea(node, 'text/x-lisp');
    cm.focus();
    return cm;
  }
  function ok(text) {
    Base.load(
      Base.req('POST', 'functor', { body: text }), 'Saving', function () {
        Layout.showMessage('Functor definition saved.', Layout.SHORT_DELAY, false);
        close();
      });
  }
  functorTemplate.cast(node, { make: make, cancel: close, ok: ok });
};

export default Query;
